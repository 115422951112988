var site = site || {};
site.client = site.client || {};

(function($) {
  $(document).on('lazybeforeunveil', function(e) {
    var isMobile = site.client.isMobile;
    var background = e.target.getAttribute('data-bg');
    if (isMobile && !!e.target.getAttribute('data-bg-mobile')) {
      background = e.target.getAttribute('data-bg-mobile');
    }
    if (background) {
      e.target.style.backgroundImage = 'url(' + background + ')';
    }
  });
})(jQuery);
